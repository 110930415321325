.skills__container {
    grid-template-columns: repeat(2, 375px);
    column-gap: 10rem;
    justify-content: center;
}
.skills__group {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Display two items per column */
    row-gap: 2rem;
    column-gap: 2.5rem;
    justify-content: center;
}

.skills__content {
    background-color: var(--container-color);
    /* border: 1px solid rgb(254, 251, 251); */
    border: 1px solid var(--box-shadow-color);
    box-shadow:  0 0 5px 5px var(--box-shadow-color);
    padding: 2rem 4rem;
    border-radius: 1.25rem;
}

.skills__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-2);
}

.skills__box {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap onto the next line */
    justify-content: center;
    column-gap: 2.5rem;
}
.skills__box{
    padding-left: 10px;
    padding-right: 10px;
}

.skills__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem; /* Add some space between each item */
}

.skills__data img {
    width: 30px;
    height: 30px;
}

.skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
    margin-top: 0.5rem; /* Add margin between image and name */
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
    .skills__container {
     grid-template-columns: max-content;
     row-gap: 2rem;
    }
 }
 
 @media screen and (max-width: 576px) {
     .skills__container {
         grid-template-columns: 1fr;
     }
 
     .skills__content {
         padding: 1.5rem;
     }
 }
 
 @media screen and (max-width: 350px) {
     .skills__box {
         column-gap: 1.25rem;
     }
 
     .skills__name {
         font-size: var(--small-font-size);
     }
 }