.project__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: var(--mb-2);
}

.project__item {
    color: var(--title-color);
    padding: 0.25rem 0.75rem;
    font-weight: var(--font-medium);
    border-radius: 0.5rem;
    text-transform: capitalize;
}

.project__item:hover {
    background-color: var(--title-color);
    color: var(--container-color);
}

.project__container {
    grid-template-columns: repeat(1, max-content);
    gap: 3rem;
    justify-content: center;
}

.project__card {
    display: grid;
    grid-template-columns: repeat(2,2fr);
    align-items: center;
    justify-content: center;
    background-color: var(--container-color);
    border: 1px solid var(--box-shadow-color);
    box-shadow:  0 0 5px 5px var(--box-shadow-color);
    padding: 1.25rem;
    border-radius: 1rem;
}
.hanb{
    display: grid;
    grid-template-columns: repeat(2,2fr);
    align-items: center;
    justify-content: space-between;
}

.showmore{
    color: var(--title-color-dark);
    cursor: pointer;
}

.project__description {
    margin-left: 1rem;
    max-width: 500px;
    margin-bottom: 1rem;
}

.project__img {
    width: 500px;
    border-radius: 1rem;
    margin-bottom: var(--mb-1);
}

.project__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-5);
}

.project__button {
    /* display: inline-block; */
    background-color: var(--title-color);
    color: var(--container-color);
    padding: 0.5rem 0.5rem;
    width: 100px;
    text-align: center;
    border-radius: 1rem;
    font-weight: var(--font-medium);
    /* margin-top: 0.5rem; */
    margin-left: 60%;
    /* right: -100%; */
    /* bottom: -1; */
}

.project__button__disabled{
    pointer-events: none;
    display: none;
}

.project__button:hover {
    background-color: var(--title-color-dark);
}

.project__description {
    max-width: 500px;
    margin-bottom: 1rem;
}

@media screen and (max-width: 1100px) {
    .project__container {
        gap: 1.5rem;
        grid-template-columns: repeat(1, max-content);
        justify-content: center;
    }
    
    .project__description {
        align-items: center;
        grid-template-columns: repeat(1fr);
        gap: 3rem;
    }

    .project__card {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        align-items: center;
        justify-content: center;
    }

    .project__img {
        margin-bottom: 0.75rem;
    }

    .project__title {
        margin-bottom: 0.25rem;
    }
}

@media screen and (max-width: 768px) {
    .project__container {
        grid-template-columns: max-content;
    }
    .project__card {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 576px) {
    .project__container {
        grid-template-columns: 1fr;
    }

    .project__img {
        width: 100%;
    }
    .project__card {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        align-items: center;
        justify-content: center;
    }
    .project__button {margin-left: 0;
    }
}


@media screen and (max-width: 350px) {
    .project__item {
        font-size: var(--small-font-size);
    }

    .project__filters {
        column-gap: 0.25rem;
    }
    .project__card {
        display: grid;
        /* grid-template-columns: repeat(1,1fr); */
        align-items: center;
        justify-content: center;
    }
    
}
